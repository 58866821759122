import React from "react"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'

import PeopleHeader from "./people/PeopleHeader"
import PeopleInfo from "./people/PeopleInfo"

const People = (props) => {
  React.useEffect(() => {
    const position = parseInt(sessionStorage.getItem("position"))
    if (position) {
      window.scrollTo(0, position)
      sessionStorage.removeItem("position")
    }
  }, [])

  return (
    <>
      <div className="content">
        <div className="container">
          <PeopleHeader {...props} />
        </div>
      </div>
      <div className="content right-flushed-mobile">
        <div className="container">
          <PeopleInfo {...props} />
          <div className="people">
            <div className="people__image">
              <Img
                fluid={props.image.localFile.childImageSharp.fluid}
                alt={props.image.altText}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default People
