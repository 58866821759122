import React from "react"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'
import Link from "../utils/link"

import ContactInfo from "./contact/ContactInfo"
import Map from "./contact/Map"

const Contact = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="content">
        <div className="container">
          <Fade 
              bottom 
              distance='40px'
            >
            <ContactInfo {...props} />
          </Fade>
        </div>
      </div>
      { props.googleMap && 
        <div className="content fullwidth-mobile contact--image">
          <div className="container">
            <Fade 
              bottom 
              distance='40px'
            >
              <Map {...props} />
              <div className="contact__social">
                <div>
                  <Link to='https://www.facebook.com/stuartfamilylawyers/' className="contact__social_link">
                    Facebook
                  </Link>
                </div>
                <div>
                  <Link to='https://www.linkedin.com/company/stuart-family-lawyers' className="contact__social_link">
                    LinkedIn
                  </Link>
                </div>
                { props.socials.instagram &&
                  <div>
                    <Link to={props.socials.instagram} className="contact__social_link">
                      Instagram
                    </Link>
                  </div>
                }
              </div>
            </Fade>
          </div>
        </div>
      }
    </>
  )
}

export default Contact
