import React from "react"

import "./People.scss"

const PeopleHeader = (props) => {
  return (
    <div>
      <div className="people__header" dangerouslySetInnerHTML={{ __html: props.title }} />
      { props.subTitle && <div className="people__details first" dangerouslySetInnerHTML={{ __html: props.subTitle }} /> }
    </div>
  )
}

export default PeopleHeader
