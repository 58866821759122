import React from 'react'

import Hero from '../components/home/Hero'
import ServicesSection from '../components/home/ServicesSection'
import Contact from '../components/contact-block'
import People from '../components/people-block'

const renderBlock = (param, el, i, func=null) => {
  let block = {
    'page_Acf_ContentBlocks_Hero' : (el, i) => <Hero key={i} {...el} />,
    'page_Acf_ContentBlocks_Services' : (el, i) => <ServicesSection key={i} {...el} />,
    'page_Acf_ContentBlocks_Contact' : (el, i) => <Contact key={i} {...el} />,
    'page_Acf_ContentBlocks_People' : (el, i) => <People key={i} {...el} />,
  }[param]

  if (!block) return null

  return block(el, i)
}

export default renderBlock
