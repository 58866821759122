import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "../../utils/link"
import { navigate } from "gatsby-link"

import "./ServicesSection.scss"

const ServicesSection = (props) => {

  React.useEffect(() => {
    const position = parseInt(sessionStorage.getItem("position"))
    if (position) {
      window.scrollTo(0, position)
      sessionStorage.removeItem("position")
    }
    if (window.location.hash === "#services") {
      const section = document.querySelector(".services-section")
      if (section) {
        window.scrollTo(0, section.offsetTop)
      }
    }
  }, [])

  const openServicePage = (e, service) => {
    e.preventDefault()
    sessionStorage.setItem("positionInitial", window.scrollY)
    navigate(`/services/${service.slug}`)
  }

  const { services } = useStaticQuery(graphql`
    query {
      services: allWpService(sort: {fields: [menuOrder], order: ASC}) {
        edges {
          node {
            title
            slug
            acf {
              subtitle
              content
            }
          }
        }
      }
    }
  `) 
  return (
    <section className="services-section">
      <div name="services">
        <div className="content">
          <div className="container">
            <p className="services-section__title">
              {props.title}
            </p>
            <p className="services-section__text">{props.subtitle}</p>
            <div className="services-section__links">
              {services.edges.map((el, index) => (
                <div key={index}>
                  <Link
                    to="#"
                    onClick={e => openServicePage(e, el.node)}
                    className="services-section__link"
                  >
                    {el.node.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesSection
