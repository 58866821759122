import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'

import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <SEO title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        <Header />
        { page.acf.contentBlocks && page.acf.contentBlocks.map((el, i) => RenderBlock(el.fieldGroupName, el, i))  }
        { page.slug !== 'contact' && <Footer /> }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        contentBlocks {
          __typename
          ... on WpPage_Acf_ContentBlocks_Hero {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_Services {
            fieldGroupName
            title
            subtitle
          }
          ... on WpPage_Acf_ContentBlocks_People {
            fieldGroupName
            title
            subTitle
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            content
          }
          ... on WpPage_Acf_ContentBlocks_Contact {
            fieldGroupName
            title
            socials {
              facebook
              linkedin
              instagram
            }
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            googleMap {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
