import React from "react"

import Link from "../../utils/link"

import "./ContactInfo.scss"

const ContactInfo = (props) => {
  return (
    <section className="contact">
      <div className="contact__header" dangerouslySetInnerHTML={{ __html: props.title }} />
      <div className="contact__details">
        <div className="contact__contact">
          <Link to="tel:+61390709832" className="contact__contact">
            +61 3 9070 9832
          </Link>
          <br />
          <Link
            to="mailto:mail@stuartfamilylawyers.com.au"
            className="contact__contact"
          >
            mail@stuartfamilylawyers.com.au
          </Link>
        </div>
        <div className="contact__address">
          Level 11, 456 Lonsdale Street
          <br />
          Melbourne VIC 3000
        </div>
      </div>
    </section>
  )
}

export default ContactInfo
