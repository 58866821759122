import React from "react"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'

import "./Hero.scss"

const Hero = (props) => {
  return (
    <section className="content fullwidth-mobile">
      <div className="container">
        <Fade 
          bottom 
          distance='40px'
        >
          <Img
  		      fluid={props.image.localFile.childImageSharp.fluid}
  		      alt={props.image.altText}
  		    />
        </Fade>
      </div>
    </section>
  )
}

export default Hero
